import {onBeforeMount, onMounted} from 'vue';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

export function useGsap() {
    function refreshScrollTrigger() {
        ScrollTrigger.refresh();
    }

    onMounted(() => {
        gsap.registerPlugin(ScrollTrigger);
        window.addEventListener('resize', refreshScrollTrigger);
    });

    onBeforeMount(() => {
        ScrollTrigger.getAll().forEach((trigger) => {
            trigger.kill();
        });
        window.removeEventListener('resize', refreshScrollTrigger);
    });

    return {
        gsap,
        ScrollTrigger,
    };
}
